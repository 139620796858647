.App {
  text-align: center;
  background: #000000;
  min-height: 100vh;
}

.commitment-progress-gradient {
  -webkit-mask-image: linear-gradient(to left, rgba(0,0,0,1), rgba(0,0,0,0)), linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0));
}

.welcome-title-gradient {
  -webkit-mask-image: linear-gradient(to right, rgba(0,0,0,0.2), rgba(0,0,0,1) 20%);
}

.landing-blur {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  opacity: 0.2;
  pointer-events: none;
}

@keyframes progressAnimation {
    from { transform: translateX(0); }
    to   { transform: translateX(-13px); }
}


.recharts-cartesian-grid-horizontal line {
  opacity: 0.1;
}

.recharts-cartesian-axis-tick .recharts-cartesian-axis-tick-value {
  position: relative;
  top: 20px;
}

.recharts-cartesian-axis-line {
  color: white !important;
  fill: white !important;
  stroke: white !important;
  stroke-width: 2px;
}

.recharts-yAxis > line {
  display:none;
}
.recharts-yAxis .recharts-cartesian-axis-tick > line {
  display:none;
}
.recharts-yAxis .recharts-cartesian-axis-tick > text {
  transform: translateX(-20px);
  color:white;
  fill:white;
}
.recharts-xAxis .recharts-cartesian-axis-tick > line {
  display:none
}
.recharts-xAxis .recharts-cartesian-axis-tick:nth-child(6) > line,
.recharts-xAxis .recharts-cartesian-axis-tick:nth-child(12) > line,
.recharts-xAxis .recharts-cartesian-axis-tick:nth-child(18) > line,
.recharts-xAxis .recharts-cartesian-axis-tick:nth-child(24) > line,
.recharts-xAxis .recharts-cartesian-axis-tick:nth-child(30) > line,
.recharts-xAxis .recharts-cartesian-axis-tick:nth-child(36) > line,
.recharts-xAxis .recharts-cartesian-axis-tick:nth-child(48) > line {
  display:inline;
  transform: translateY(8px);
}

#graphTooltip {
  position:absolute;
  display:none;
  background-color:black;
  border: 1px solid white;
  border-radius:5px;
  top:0;
  left:0;
  color:white;
  padding-left:10px;
  padding-right:10px;
  padding-top:3px;
  padding-bottom:3px;
  font-size:18px;
  font-family: 'ABC Favorit Mono';
}

#graphTooltip .toke {
  align-items:center;
  padding-right:10px;
  padding-top:3px;
}

#graphTooltip .pcts {
  display:flex;
  flex-direction: column;
  font-size:11px;
  padding-left:10px;
  align-items:center;
}

#graphTooltip .pcts .toke {
  padding:2px;
  padding-bottom:0px;
  text-align: right;
  width:100%;
  color: #0b61f4;
}
#graphTooltip .pcts .farm {
  padding:2px;
  padding-top:0px;
  text-align: right;
  width:100%;
  color: #4c35de;
}
